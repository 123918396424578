import { addDays, format, subYears, subMonths, formatISO, parseISO, setHours, setMinutes, setSeconds, startOfDay } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

const formatDateToLocal = (dateString: string, formatString: string = "EEE, dd MMM yyyy HH:mm:ss") => {
    if(!dateString){
        return "";
    }

    return format(
        utcToZonedTime(
            parseISO(dateString),
            Intl.DateTimeFormat().resolvedOptions().timeZone
        ),
        formatString
    );
}

export const formatOnlyDateToLocal = (dateString: string, formatString: string = "dd MMM yyyy") => {
    if(!dateString){
        return "";
    }

    return format(
        utcToZonedTime(
            parseISO(dateString),
            Intl.DateTimeFormat().resolvedOptions().timeZone
        ),
        formatString
    );
}

export const formatDateToUTC = (dateString: string, formatString: string = "yyyy-MM-dd'T'HH:mm:ssxxx") => {
    if (!dateString) {
        return "";
    }

    return format(zonedTimeToUtc(
        parseISO(dateString),
        Intl.DateTimeFormat().resolvedOptions().timeZone
    ), formatString);
};

export const getCurrentDate = (daysToAdd?: number) => {
    if(daysToAdd) {
        const targetDate = addDays(startOfDay(new Date()), daysToAdd);
        return formatISO(targetDate, { representation: 'complete' });
    }

    return formatISO(startOfDay(new Date()), { representation: 'complete' })
};

export const getSubMonths = (startDateString: string, months?: number) => {
    const startDate = parseISO(startDateString);
    const pastDate = subMonths(startDate, months)
  
    return formatISO(pastDate, { representation: 'complete' });
};

export const getSubYears = (startDateString: string, years?: number) => {
    const startDate = parseISO(startDateString);
    const pastDate = subYears(startDate, years);
  
    return formatISO(pastDate, { representation: 'complete' });
 };

export const getEndDate = (startDateString: string, daysToAdd?: number) => {
   const startDate = parseISO(startDateString);
   const targetDate = addDays(startDate, daysToAdd);
    const endDate = setSeconds(setMinutes(setHours(targetDate, 23), 59), 59);
 
   return formatISO(endDate, { representation: 'complete' });
};

export const createDate = (date, time) => {
    const dateObject = new Date(date);
    const [hours, minutes] = time.split(":").map(Number);

    dateObject.setHours(hours, minutes);
    const formattedDate = dateObject.toISOString();

    return formattedDate;
}

export const getDateAndTime = (formattedDate) => {
    if(formattedDate) {
        const dateObject = new Date(formattedDate);

        const hours = String(dateObject.getHours()).padStart(2, "0");
        const minutes = String(dateObject.getMinutes()).padStart(2, "0");
        const time = `${hours}:${minutes}`;

        return { date: dateObject.toISOString(), time: time =="00:00" ? time: time.replace(/^0/, '')};
    } else {
        return { date: formattedDate, time: "" };
    }
}

export default formatDateToLocal;
