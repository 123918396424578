// @ts-nocheck
import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getApiHost } from './getApiHost'
import * as uuid from 'uuid';
import Cookies from 'js-cookie';

export interface IRequest<T = any> {
    url: string;
    body?: T;
    cancelToken?: any;
    options?: AxiosRequestConfig;
}
  

const initialAxios = Axios.create({
  baseURL: getApiHost(),
  withCredentials: true,
});

initialAxios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = Cookies.get('token');
    const refresh_token = Cookies.get('refresh_token');

    token && (config.headers.Authorization = `Bearer ${token}`);
    refresh_token && (config.headers.refresh_token = refresh_token);

    config.headers.common = {
      ...config.headers.common,
      requestTraceId: uuid.v4(),
    };
    return config;
  },
  (error) => Promise.reject(error)
);

initialAxios.interceptors.response.use(
  (response) => {
    const newAccessToken = response.headers.get('x-new-access-token');
    const newRefreshToken = response.headers.get('x-new-refresh-token');

    newAccessToken && Cookies.set('token', decodeURIComponent(newAccessToken), { expires: 8 / 24 });
    newRefreshToken && Cookies.set('refresh_token', decodeURIComponent(newRefreshToken), { expires: 1 });

    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      window.location.href = `${getApiHost()}/logout`;
      return;
    }
    return Promise.reject(error);
  }
);

const Api = {
  post: <TRequest = any, TResponse = any>({
    url,
    body,
    cancelToken,
    options = {},
  }: IRequest<TRequest>): Promise<AxiosResponse<TResponse>> =>
    initialAxios.post(url, body, { ...options, cancelToken }),

  delete: ({ url, body, options = {} }: IRequest): Promise<any> =>
    initialAxios.delete(url, {data: body}, options),

  get: <T = any>({ url, options }: IRequest): Promise<AxiosResponse<T>> =>
    initialAxios.get(url, options).catch((error) => ({ hasError: true, ...error })),

  put: <TRequest = any, TResponse = any>({
    url,
    body,
    options = {},
  }: IRequest<TRequest>): Promise<AxiosResponse<TResponse>> =>
    initialAxios.put(url, body, options),

  patch: <T>({ url, body, options = {} }: IRequest): Promise<AxiosResponse<T>> =>
    initialAxios.patch(url, body, options),
};

export default Api;
