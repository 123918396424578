export const removeComments = (html) => {
       // Remove single-line comments in script tags while preserving strings
       return html.replace(/<script[\s\S]*?>[\s\S]*?<\/script>/gi, (script) => {
           return script.replace(/(\/\/.*)(?=(?:(?:[^"'\\]*(?:"|'|`|\\|$))+))?/gm, (match, comment, offset, input) => {
               // Count quotes to ensure `//` is outside strings
               const before = input.slice(0, offset);
               const inQuotes = (before.match(/['"`]/g) || []).length % 2 !== 0;
               return inQuotes ? match : "";
           });
       });
  }