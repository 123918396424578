import { Store } from "../types";
import formatDateToLocal from "./formatDate";

const getParams = (filter, value, brand: Store) => {
    switch(filter){
        case "all_products": {
            return ""
        }
        case "inventory_eq-0": {
            return "inventory_level=0"
        }
        case "inventory_gt-0": {
            return "inventory_level:greater=0"
        }
        case "inventory_eq-1": {
            return "inventory_level=1"
        }
        case "inventory_gt-1": {
            return "inventory_level:greater=1"
        }
        case "new": {
            return "condition=New"
        }
        case "used": {
            return "condition=Used"
        }
        case "no_image_products": {
            return `categories:in=${brand.productsWithoutImages}`
        }
        case "products_with_images": {
            return `categories:not_in=${brand.productsWithoutImages}`
        }
        case "published_status": {
            return "is_featured=1"
        }
        case "unpublished_status": {
            return "is_featured=0"
        }
        case "visible_status": {
            return "is_visible=1"
        }
        case "invisible_status": {
            return "is_visible=0"
        }
        case "price_min": {
            return value ?`price:min=${value}`: ""
        }
        case "price_max": {
            return value ?`price:max=${value}`:""
        }
        case "date_min": {
            return value ? `date_modified:min=${formatDateToLocal(value, "yyyy-MM-dd")}`: ""
        }
        case "date_max": {
            return value ? `date_modified:max=${formatDateToLocal(value, "yyyy-MM-dd")}`: ""
        }
        case "category": {
            return value.length ? `categories:in=${value.join()}`: ""
        }
        case "asc_order": {
            return "direction=asc"
        }
        case "desc_order": {
            return "direction=desc"
        }
        case "date_modified_sort": {
            return "sort=date_modified"
        }
        case "name_sort": {
            return "sort=name"
        }
    }

    return ""
}

const getQueryParam = (queryClient, { selectedFilters, sortConfig })=> {
    const brand = queryClient.getQueryData(['store']) as Store;
    let queryParam;

    if(selectedFilters.advance) {
    const queries = []
    Object.keys(selectedFilters.advance).forEach(filter => {
        if(filter) {
            const q = getParams(filter, selectedFilters.advance[filter], brand)
            q && queries.push(q);
        } 
    })
    queryParam =  queries.join("&");
    } else if(selectedFilters.search){
    queryParam = `keyword:like=${selectedFilters.search}`;
    } else {
    queryParam = getParams(selectedFilters.quickSearch, "", brand);
    }

    return `${queryParam}&direction=${sortConfig.direction}&sort=${sortConfig.sort}`;
}

export default getQueryParam;